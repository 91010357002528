import React from "react";
import logo from "../assets/logo/usyd-logo2.svg";

const styles = {
  footer: {
    backgroundColor: "#e64626",
    position: "absolute",
    right: "0",
    left: "0",
    padding: "1rem",
  },
  footerImage: {
    height: "100%",
    // marginLeft: "90px",
    marginTop: "20px",
  },
  footerText: {
    color: "black",
    fontSize: "12px",
    float: "right",
    marginRight: "90px",
    fontFamily: "Apercu mono, Courier, monospace",
    marginTop: "40px",
  },
};

class Footer extends React.Component {
  render() {
    return (
      <div style={styles.footer}>
        <img src={logo} alt="Logo" style={styles.footerImage} />
        <div style={styles.footerText}>
          <div>The University of Sydney</div>
          <div>ABN: 15 211 513 464</div>
          <div>CRICOS Number: 00026A</div>
        </div>
      </div>
    );
  }
}

export default Footer;
