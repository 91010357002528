import React from "react";
import Collapsible from "react-collapsible";
import logo from "../assets/logo/toby.jpg";

const styles = {
  body: {
    padding: "1px",
    marginTop: "80px",
  },
  bodyHeadingText: {
    color: "black",
    textAlign: "center",
    fontFamily: "Arial, sans-serif",
  },
};

class Profile extends React.Component {
  render() {
    return (
      <div style={styles.body}>
        <h2 style={styles.bodyHeadingText}>Arnold's Glossary of Anatomy </h2>

        <p style={styles.bodyHeadingText}>
          This anatomical word search engine is dedicated to the memory of{" "}
          <Collapsible
            trigger={<div style={{ color: "blue" }}>Maurice (Toby) Arnold</div>}
          >
            <p style={styles.bodyHeadingText} style={{ fontWeight: "bold" }}>
              Modifications & Additions by Deborah Bryce
            </p>
            <img src={logo} alt="Toby" />
            <p>
              MBBCh (Witwatersrand), FRCS (Edinburgh), MD (Hon. causa) (NSW)
              South African by birth, Professor Arnold served as a surgeon in
              the Union Defence Force (1940-46) in North Africa, the Middle East
              and Italy. He taught generations of medical students at the
              University of Witwatersrand (1946-60) and, in this country, at the
              Universities of Queensland (1961-3), Sydney (1971-1983) and New
              South Wales (1966-70, 1983-1994). He was author of Reconstructive
              Anatomy (1968) and Man's Anatomy (1983).
            </p>
            <p>
              In the Department of Anatomy and Histology at The University of
              Sydney, he developed the catalogues of the J.T. Wilson Museum and
              taught colleagues and students with learning and infectious
              enthusiasm. This glossary is another of his achievements.
            </p>
            <p>
              Gladly would he learn, and gladly teach (from Chaucer's Tales, of
              the scholar Oxenford).
            </p>
          </Collapsible>
        </p>
      </div>
    );
  }
}

export default Profile;
