import React from "react";
import logo from "../assets/logo/usyd-logo2.svg";

const styles = {
  header: {
    backgroundColor: "white",
    textAlign: "left",
    padding: "22px",
    position: "fixed",
    left: "0",
    top: "0",
    width: "100%",
    marginTop: "46px",
  },
  headerImage: {
    height: "100%",
    // marginLeft: "90px"
  },

  headerText: {
    color: "black",
    fontSize: "15px",
    margin: "0",
  },
};

class Header extends React.Component {
  render() {
    return (
      <div style={styles.header}>
        <a href="https://www.sydney.edu.au/">
          <img src={logo} alt="Logo" style={styles.headerImage} />
        </a>
      </div>
    );
  }
}

export default Header;
