import React from "react";

const styles = {
  banner: {
    backgroundColor: "#363636",
    height: "37px",
    paddingTop: "9px",
    width: "100%",
    position: "fixed",
    left: "0",
    top: "0",
  },
  bannerText: {
    textAlign: "right",
    color: "white",
    lineHeight: "1em",
    float: "right",
    marginRight: "200px",
    marginTop: "5px",
  },
  bannerHref: { color: "white", textDecoration: "none" },
};

class Banner extends React.Component {
  render() {
    return (
      <div style={styles.banner}>
        <div style={styles.bannerText}>
          <a
            style={styles.bannerHref}
            href="https://www.sydney.edu.au/medicine-health/schools/school-of-medical-sciences/discipline-of-anatomy-and-histology.html"
          >
            Anatomy & Histology
          </a>
        </div>
      </div>
    );
  }
}

export default Banner;
