import React from "react";
import Header from "./components/header";
import Profile from "./components/profile";
import FILE from "../src/assets/glossary.txt";
import Footer from "./components/footer";
import Banner from "./components/banner";

const styles = {
  container: {
    padding: "1em",
    fontFamily: "Arial, sans-serif",
    // marginLeft: "90px",
    // marginRight: "90px",
    minHeight: "100vh",
  },
  body: {
    padding: "50px",
    minHeight: "100vh",
  },
  bodyHeadingText: { color: "black", textAlign: "center" },
  // button: { padding: "4px" },
};

class App extends React.Component {
  state = {
    searchInput: "a",
    glossaryDisplayList: [],
    originalGlossaryDisplayList: [],
  };

  componentDidMount = () => {
    this.loadFile();
  };

  loadFile = () => {
    fetch(FILE)
      .then((r) => r.text())
      .then((t) => {
        var array = t.toString().replace(/\r\n/g, "\n").split("\n");
        this.setState({
          glossaryDisplayList: array,
          originalGlossaryDisplayList: array,
        });
      });
  };

  onSearchInputChange = (e) => {
    this.setState({ searchInput: e.target.value });
    const list = this.state.originalGlossaryDisplayList;
    let result = [];
    list.map(function (val) {
      const firstWord = val.split(":")[0];
      if (firstWord.startsWith(e.target.value)) result.push(val);
      return null;
    });
    this.setState({ glossaryDisplayList: result });
  };

  onAlphabetClick = (e) => {
    this.setState({ searchInput: e.target.value.toLowerCase() });
    let filteredList = this.state.originalGlossaryDisplayList.filter(function (
      listWord
    ) {
      return listWord[0].toUpperCase() === e.target.value;
    });
    this.setState({ glossaryDisplayList: filteredList });
  };

  prepareAlphabets = () => {
    let result = [];
    for (let i = 65; i < 91; i++) {
      result.push(
        <button
          style={{ margin: "6px" }}
          type="button"
          key={i}
          onClick={this.onAlphabetClick}
          value={String.fromCharCode(i)}
        >
          {String.fromCharCode(i)}
        </button>
      );
    }
    return result;
  };

  displayList = () => {
    const { searchInput, glossaryDisplayList } = this.state;
    let result = [];

    if (searchInput === "") {
      this.state.originalGlossaryDisplayList.map(function (val) {
        const firstWord = val.split(":")[0];
        if (firstWord.includes("a"))
          result.push(<p style={{ color: "black" }}>{val}</p>);
        return null;
      });
      return result;
    }

    glossaryDisplayList.map(function (val) {
      const firstWord = val.split(":")[0];
      if (firstWord.startsWith(searchInput))
        result.push(<p style={{ color: "black" }}>{val}</p>);
      return null;
    });
    return result;
  };

  render() {
    return (
      <div style={styles.container}>
        <Banner></Banner>
        <Header></Header>
        <div style={styles.body}>
          <Profile></Profile>
          <div style={{ textAlign: "center" }}>
            <p style={{ margin: "auto", fontWeight: "bold" }}>
              {" "}
              Enter a search term below{" "}
            </p>
            <p>
              <input
                style={{ width: "300px", height: "25px" }}
                type="search"
                onChange={this.onSearchInputChange}
                value={this.state.searchInput}
              />
            </p>
            {this.prepareAlphabets()}
          </div>

          {this.displayList()}
        </div>
        <Footer></Footer>
      </div>
    );
  }
}

export default App;
